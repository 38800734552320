import React from "react"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const IndexPage = () => {
  return (
    <>
      <SEO title="Oops did not find that page" lang="en" />
      <Layout>
        <p>404 did not find that page</p>
      </Layout>
    </>
  )
}



export default IndexPage
